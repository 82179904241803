<template>
  <v-card color="accent" dark>
    <v-card-title>Aviso</v-card-title>
    <v-card-text>Está sessão ainda não está completa!</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "UserTabSecurity",
};
</script>

<style></style>
